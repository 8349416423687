// ReversePrivateRoute.js

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Placeholder from 'react-bootstrap/Placeholder';

const ReversePrivateRoute = ({ user }) => {
    let value = (
        <Container style={{ width: '500px', marginTop: '30px', fontSize: '18px' }} className="border p-4">
        <Placeholder animation="wave">
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
        </Placeholder>
      </Container>
    );

    if (user !== null && user !== false) {
        value = (<Navigate to="/" />);
    } else if (user === false){
        value = (<Outlet />);
    }

    return value;
};

export default ReversePrivateRoute;
