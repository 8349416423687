import React, { useEffect, useState } from 'react';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  limit, 
  startAfter,
  orderBy,
} from 'firebase/firestore';
import Button from 'react-bootstrap/Button';
import { db } from '../Config/firebase'; 
import OfferCard from './OfferCard'; 

const OffersList = ({ category, condition, search, sort, customKey }) => {
  const [offers, setOffers] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const [itemLimit, setItemLimit] = useState(3);
  const [lastSearchKey, setLastSearchKey] = useState(customKey);
  
  useEffect(() => {
    clearOffersIfNewSearch();
    fetchOffers();
  }, [category, condition, search, sort, customKey]);

  const clearOffersIfNewSearch = () => {
    if (lastSearchKey === customKey) {
      return;
    }
    setOffers([]);
    setLastKey(null);
    setLastSearchKey(customKey);
  };

  const sortBy = () => {
    switch (sort) {
      case 'Newest':
        return orderBy('created', 'desc');
      case 'Oldest':
        return orderBy('created', 'asc');
      case 'Price: Low to High':
        return orderBy('price', 'asc');
      case 'Price: High to Low':
        return orderBy('price', 'desc');
      default:
        return orderBy('created', 'desc'); 
    }
  };
  
  const fetchOffers = async () => {
    try {
      const offersCollection = collection(db, 'offers');
      let q = query(offersCollection);

      if (category && condition) {
        q = query(offersCollection, 
          where('category', '==', category), 
          where('condition', '==', condition)
        );
      } else if (category) {
        q = query(offersCollection, 
          where('category', '==', category)
        );
      } else if (condition) {
        q = query(offersCollection, 
          where('condition', '==', condition)
        );
      } 
    
      q = query(q, where('disabled', '==', false), sortBy(), limit(itemLimit)); 
      
      if (lastKey !== null) {
        q = query(q, startAfter(lastKey));
      }

      const offersSnapshot = await getDocs(q);
      const newOffersData = offersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      if (newOffersData.length === 0) {
        return;
      }
      setLastKey(newOffersData[newOffersData.length - 1].created);
      setOffers((prevOffers) => [...prevOffers, ...newOffersData]); 
    }
    catch (error) {
      console.error('Error fetching offers:', error);
    }
  };

  const handleLoadMore = () => {
    clearOffersIfNewSearch();
    fetchOffers();
  };
  
  return (  
    <>
      <h2>Results for {category === '' ? 'All Categories' : category} ({condition === '' ? 'All Conditions' : condition})</h2>
      <div className="offer-cards">
        {offers.map(offer => (
          <OfferCard key={offer.id} offer={offer} />
        ))}
      </div>
      {offers.length === 0 && <p>No offers found. Please try a different condition.</p>}
      <Button 
        onClick={handleLoadMore}
        variant="primary"
        className='mt-3 '
        hidden={offers.length % itemLimit !== 0 || offers.length === 0}
      >Load More</Button>
    </>
  );
};

export default OffersList;
