import { useEffect, useState, useContext } from 'react';
import { auth } from '../Config/firebase';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { UserContext } from '../Context/UserContext';
import { Button } from 'react-bootstrap';


const EmailVerificationCheck = () => {
    document.title = 'ErasmusTrade | Verify Email';
    const { contextUser, contextData } = useContext(UserContext);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const navigate = useNavigate();  

    useEffect(() => {
        const checkEmailVerification = async () =>{
            if  (contextUser) {
                setIsEmailVerified(contextUser.emailVerified);
                if (isEmailVerified) {
                    navigate('/');
                }
        }
    }

        checkEmailVerification();
    }, []);

    return (
        <Container style={{ minHeight: '80vh' }}>
            {isEmailVerified ? (
                <p>Your email has been verified.</p>
            ) : (
                <>
                <p>Please verify your email to access all features. Check your email for a verification link.</p>
                <Button onClick={() => auth.sendEmailVerification(contextUser)}>Resend verification email</Button>
                </>
            )}
        </Container>
    );
};

export default EmailVerificationCheck;
