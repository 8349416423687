import React, { useState, useContext, useEffect } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, storage } from '../Config/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import categoriesData from '../Data/categories.json';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Context/UserContext';

const CreateOfferForm = () => {
  const { contextUser } = useContext(UserContext);
  const navigate = useNavigate();

  const categories = categoriesData.categories;
  const conditions = categoriesData.conditions;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCondition, setCondition] = useState('');
  const [price, setPrice] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [image, setImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageURL, setImageURL] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    category: false,
    condition: false,
    price: false,
    image: false,
  });

  useEffect(() => {
    const checkEmailVerification = async () => {
      if (contextUser && !contextUser.emailVerified) {
        navigate('/verify-email');
      }
    };

    checkEmailVerification();
  }, [contextUser]);

  const clearFormFields = () => {
    setName('');
    setDescription('');
    setCondition('');
    setPrice('');
    setSelectedCategory('');
    setImage(null);
    setIsUploading(false);
    setProgress(0);
    setImageURL('');
    setSuccessMessage('');
    setErrors({
      name: false,
      description: false,
      category: false,
      condition: false,
      price: false,
      image: false,
    });
  };

  const handleImageUpload = async () => {
    try {
      if (image) {
        const uuid = v4();
        const storageRef = ref(storage, `images/${image.name}-${uuid}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        setIsUploading(true);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
          },
          (error) => {
            console.error(error);
            setIsUploading(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setIsUploading(false);
              setProgress(0);
              setImageURL(downloadURL);
            });
          }
        );
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleCreateOffer = async () => {
    const validationErrors = {};

    if (name.trim() === '') {
      validationErrors.name = true;
    }

    if (description.trim() === '') {
      validationErrors.description = true;
    }

    if (selectedCategory.trim() === '') {
      validationErrors.category = true;
    }

    if (selectedCondition.trim() === '') {
      validationErrors.condition = true;
    }

    if (price.trim() === '') {
      validationErrors.price = true;
    }

    if (!imageURL) {
      validationErrors.image = true;
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    try {
      const offersCollection = collection(db, 'offers');
      await addDoc(offersCollection, {
        owner: contextUser.uid,
        name,
        description,
        condition: selectedCondition,
        price: parseFloat(price),
        category: selectedCategory,
        image: imageURL,
        created: serverTimestamp(),
        disabled: false,
      });

      clearFormFields();
      setSuccessMessage('Offer created successfully!');
    } catch (error) {
      console.error('Error creating offer:', error);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  return (
    <Container className="my-4">
      <Form className="border p-4">
        <h2 className="mb-4">Create Your Offer</h2>
        {successMessage && (
          <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
            {successMessage}
          </Alert>
        )}

        <Form.Group className="mb-4" controlId="offer-name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors({ ...errors, name: false });
            }}
            className={errors.name ? 'is-invalid' : ''}
          />
          {errors.name && <div className="invalid-feedback">Name cannot be empty</div>}
        </Form.Group>

        <Form.Group className="mb-4" controlId="offer-description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setErrors({ ...errors, description: false });
            }}
            className={errors.description ? 'is-invalid' : ''}
          />
          {errors.description && <div className="invalid-feedback">Description cannot be empty</div>}
        </Form.Group>

        <Form.Group className="mb-4" controlId="offer-selectedCategory">
          <Form.Label>Category</Form.Label>
          <Form.Select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              setErrors({ ...errors, category: false });
            }}
            className={errors.category ? 'is-invalid' : ''}
          >
            <option value="" disabled>Select a category</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Form.Select>
          {errors.category && <div className="invalid-feedback">Category cannot be empty</div>}
        </Form.Group>

        <Form.Group className="mb-4" controlId="offer-condition">
          <Form.Label>Condition</Form.Label>
          <Form.Select
            value={selectedCondition}
            onChange={(e) => {
              setCondition(e.target.value);
              setErrors({ ...errors, condition: false });
            }}
            className={errors.condition ? 'is-invalid' : ''}
          >
            <option value="" disabled>Select a condition</option>
            {conditions.map((condition) => (
              <option key={condition} value={condition}>
                {condition}
              </option>
            ))}
          </Form.Select>
          {errors.condition && <div className="invalid-feedback">Condition cannot be empty</div>}
        </Form.Group>

        <Form.Group controlId="offer-price" className="mb-4">
          <Form.Label>Price</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
                setErrors({ ...errors, price: false });
              }}
              className={errors.price ? 'is-invalid' : ''}
            />
            <InputGroup.Text>€</InputGroup.Text>
            {errors.price && <div className="invalid-feedback">Price cannot be empty or negative</div>}
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-4" controlId="offer-image">
          <Form.Label>Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              handleImageChange(e);
              setErrors({ ...errors, image: false });
            }}
            className={errors.image ? 'is-invalid' : ''}
          />
          {errors.image && <div className="invalid-feedback">Select an image</div>}
        </Form.Group>

        {image && (
          <div>
            <Button onClick={handleImageUpload} disabled={isUploading} className="mb-2" variant="secondary">
              Upload Image
            </Button>
            {isUploading && <ProgressBar animated now={progress} />}
            {imageURL && (
              <div>
                <Image src={imageURL} alt="Uploaded" fluid rounded style={{ border: 'solid black', maxWidth: '100%', maxHeight: '300px', margin: '10px 0' }} />
              </div>
            )}
          </div>
        )}

        <Button variant="primary" onClick={handleCreateOffer}>
          Create Offer
        </Button>
      </Form>
    </Container>
  );
};

export default CreateOfferForm;
