// PrivateRoute.js

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ user }) => {

    if (user !== null && user !== false) {
        return <Outlet />;
    } 
    else if (user === false) {
        return <Navigate to="/login" />;
        
    }else {
        return(
            <div>
                Loading...
            </div>
        );
    }

};

export default PrivateRoute;