export const getDate = (rawDate) => {
    if (!rawDate) {
        console.error('No date provided');
        return;
    }
    const createdDate = rawDate.toDate();
    return createdDate.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };