import { 
  db, 
  auth
} from '../Config/firebase';
import { useState } from 'react';
import { 
  doc, 
  runTransaction, 
  serverTimestamp 
} from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';

const SignUp = () => {
  document.title = 'ErasmusTrade | Sign Up';

  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('Invalid email address');
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const navigate = useNavigate();

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePassword = (password) => /^(.{6,})?$/.test(password);
  const validatePhoneNumber = (phoneNumber) => /^\d{10,15}$/.test(phoneNumber); 

  const isNumber = (value) => /^\d*$/.test(value);

  const handleNameChange = (e) => {
    const newNameValue = e.target.value;
    setNewName(newNameValue);
    setIsNameEmpty(false);
  };

  const handlePhoneChange = (e) => {
    const newPhoneNumberValue = e.target.value;
    if (isNumber(newPhoneNumberValue)) {
      setNewPhoneNumber(newPhoneNumberValue);
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const clearInput = () => {
    setNewName('');
    setNewEmail('');
    setNewPassword('');
    setNewPhoneNumber('');
  };
  

const createUser = async () => {
  if (auth.currentUser) {
    navigate('/');
  }

  if (!validateEmail(newEmail)) {
    setIsValidEmail(false);
    return;
  }
  if (!validatePassword(newPassword)) {
    setIsValidPassword(false);
    return;
  }

  if (!(newName.length > 0)) {
    setIsNameEmpty(true);
    return;
  }


  if (!validatePhoneNumber(newPhoneNumber)) {
    setIsPhoneValid(false);
    return;
  }

  try {
    await createUserWithEmailAndPassword(auth, newEmail, newPassword);
    const user = auth.currentUser;

    await sendEmailVerification(user);

    await runTransaction(db, async (transaction) => {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocData = {
        name: newName,
        email: newEmail,
        phone: newPhoneNumber,
        created: serverTimestamp(),
      };
      transaction.set(userDocRef, userDocData);
    });

    setSuccessMessage('User created successfully! Please verify your email address.');
    clearInput();

    let interval = setInterval(async () => {
      if (user.emailVerified) {
          clearInterval(interval);
          navigate("/");
      }
     await user.reload();
  }, 2000);

  } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setEmailError("The email address is already in use");
        setIsValidEmail(false);
    } else if (error.code === "auth/invalid-email") {
        setEmailError("The email address is not valid.");
        setIsValidEmail(false);
    } else {
      setErrorMessage('There was an error creating the user. Please try again later.');
    }
  }
};

  return (
    <Container className="d-flex flex-column align-items-center justify-content-start" style={{ padding: '10px', minHeight: '80vh' }}>
       <Form style={{ width: '100%', maxWidth: '500px', fontSize: '16px' }} className="border p-3">
          <h2>Sign Up</h2>

          {successMessage && (
          <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
            {errorMessage}
          </Alert>
        )}

          <Form.Group className="mb-4" controlId="signup-email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
                setIsValidEmail(true);
                setEmailError('Invalid email address');
              }}
              className={isValidEmail ? '' : 'is-invalid'} 
              style={{ fontSize: '16px' }}
            />
            {!isValidEmail && <div className='invalid-feedback'>{emailError}</div>}
          </Form.Group>

          <Form.Group className="mb-4" controlId="signup-password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Choose a password"
              onChange={(e) => {
                setNewPassword(e.target.value);
                setIsValidPassword(true);
              }}
              value={newPassword}
              className={isValidPassword ? '' : 'is-invalid'} 
              style={{ fontSize: '16px' }}
            />
             <Form.Text id="passwordHelpBlock" muted>
              Your password must be at 6 least characters long.
            </Form.Text>
            {!isValidPassword && <div className='invalid-feedback'>Password is too short</div>}
          </Form.Group>

          <Form.Group className="mb-4" controlId="signup-name">
            <Form.Label>Display Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => handleNameChange(e)}
              placeholder="Enter your name"
              value={newName}
              style={{ fontSize: '16px' }}
              className={isNameEmpty ? 'is-invalid' : ''}
            />
            {isNameEmpty && <div className="invalid-feedback">Name cannot be empty</div>}
          </Form.Group>

          <Form.Group controlId="signup-phone" className="mb-4">
          <Form.Label>Phone Number</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text>+</InputGroup.Text>
            <Form.Control
              type="text"
              isInvalid={!isPhoneValid} 
              placeholder="Enter your phone number"
              onChange={(e) => {handlePhoneChange(e)}}
              value={newPhoneNumber}
              style={{ fontSize: '16px' }}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid phone number
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

          <Button variant="primary" onClick={() => createUser()} style={{ fontSize: '18px', marginTop: '10px' }}>
            Sign Up
          </Button>
        </Form>
    </Container>
  );
};

export default SignUp;
