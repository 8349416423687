import React, { useState, useContext, useEffect } from 'react';
import { Container, Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import { Placeholder } from 'react-bootstrap';
import { db } from '../Config/firebase';
import { doc, runTransaction, getDoc, } from 'firebase/firestore';

import LowerNavbar from '../Components/LowerNavbar';
import { UserContext } from '../Context/UserContext';

const AccountPage = () => {
  document.title = 'ErasmusTrade | Account';

  const { contextUser, contextData } = useContext(UserContext);

  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validatePhoneNumber = (phoneNumber) => /^\d{10,15}$/.test(phoneNumber); 
  const isNumber = (value) => /^\d*$/.test(value);
  
  const handlePhoneChange = (e) => {
    const newPhoneNumberValue = e.target.value;
    if (isNumber(newPhoneNumberValue)) {
      setNewPhoneNumber(newPhoneNumberValue);
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  useEffect(() => {
    if (!contextUser || !contextData) {
      setIsLoading(false);
      return;
    }
    setNewName(contextData.name);
    setNewEmail(contextUser.email);
    setNewPhoneNumber(contextData.phone);
    setIsLoading(false); 
  }, [contextData, contextUser]);

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleEdit = (e) => {
    e.preventDefault(); 
    console.log('editing');
    setIsEditing(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(newName.length > 0)) {
      setIsNameEmpty(true);
      return;
    }

    if (!validatePhoneNumber(newPhoneNumber)) {
      setIsPhoneValid(false);
      return;
    }
   

    try {
      await runTransaction(db, async (transaction) => {
        const userRef = doc(db, 'users', contextUser.uid);
        const userDoc = await getDoc(userRef);
        if (!userDoc.exists()) {
          throw new Error('User does not exist');
        }
        transaction.update(userRef, { name: newName, phone: newPhoneNumber });
      });
    }
    catch (error) {
      console.error('Error updating user:', error);
    }

    setIsEditing(false);
  };


  return (
    <>
      <LowerNavbar />
      <Container className="d-flex flex-column align-items-center justify-content-start" style={{ padding: '10px', minHeight: '75vh' }}>
        {isLoading ? ( // Render placeholder if loading
          <Placeholder animation="glow">
            <Placeholder xs={12} />
            <Placeholder xs={10} />
            <Placeholder xs={8} />
            <Placeholder xs={6} />
            <Placeholder xs={4} />
            <Placeholder xs={2} />
          </Placeholder>
        ) : (
          <Form onSubmit={(e) => handleSubmit(e)} style={{ width: '100%', maxWidth: '500px', fontSize: '16px' }} className="border p-3">
            <h2>Account Information</h2>

            <Form.Group controlId='account-email' className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" value={newEmail} disabled />
            </Form.Group>

            <Form.Group controlId="account-name" className="mb-4">
              <Form.Label>Name</Form.Label>
              <Form.Control 
                type="text" 
                value={newName} 
                onChange={(e) => {handleNameChange(e); setIsNameEmpty(false);}} 
                disabled={!isEditing} 
                placeholder='Enter your name'
                isInvalid={isNameEmpty}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="signup-phone" className="mb-4">
              <Form.Label>Phone Number</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text>+</InputGroup.Text>
                <Form.Control
                  type="text"
                  isInvalid={!isPhoneValid} 
                  placeholder="Enter your phone number"
                  onChange={(e) => {handlePhoneChange(e); setIsPhoneValid(true);}}
                  style={{ fontSize: '16px' }}
                  value={newPhoneNumber}
                  disabled={!isEditing} 
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid phone number
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Row>
              <Col>
                {isEditing ? (
                  <>
                    <Button 
                      variant="primary" 
                      style={{ fontSize: '18px', marginRight: '10px'}}
                      onClick={() => {
                        setNewName(contextData.name);
                        setNewPhoneNumber(contextData.phone);
                        setIsEditing(false);
                        setIsNameEmpty(false);
                        setIsPhoneValid(true);
                      }}
                    >
                      Discard Changes
                    </Button>

                    <Button variant="light" type="submit" style={{ fontSize: '18px' }}>
                      Save Changes
                    </Button>
                  </>
                ) : (
                  <Button variant="primary" onClick={(e) => handleEdit(e)} style={{ fontSize: '18px' }}>
                    Edit
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
};

export default AccountPage;
