import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LowerNavbar from '../Components/LowerNavbar';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  document.title = 'ErasmusTrade | About';
  return (
    <>
      <LowerNavbar />
      <Container className="mt-4" style={{ minHeight: '70vh' }}>
        <Row className="justify-content-center">
          <Col xs={10} md={8}>
            <div className="text-center">
              <h2>Created by Erasmus students, for Erasmus students.</h2>
            </div>
            <p className="text-justify mt-3">
              At the heart of our mission is a commitment to creating a sustainable and supportive network for Erasmus students worldwide. We aim to simplify the process of sharing items by providing a platform where students can connect, share, and contribute to a more sustainable and resourceful community.
            </p>
            <p className="text-justify">
              If you have any suggestions, feedback, or questions, please feel free to use the <Link to="/contact">contact form</Link> to get in touch with us. We are always looking for ways to improve our platform and would love to hear from you.
            </p>
            <p className="text-justify">
              We hope you enjoy using ErasmusTrade as much as we have enjoyed creating it.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutPage;
