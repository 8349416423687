import React, { useState, useContext, useEffect } from 'react';
import { serverTimestamp, doc, getDoc, updateDoc } from 'firebase/firestore';
import { 
  db, 
  storage
} from '../Config/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import categoriesData from '../Data/categories.json';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Placeholder from 'react-bootstrap/Placeholder';
import InputGroup from 'react-bootstrap/InputGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Image from 'react-bootstrap/Image';

import { UserContext } from '../Context/UserContext';

const CreateOfferForm = () => {
  document.title = 'ErasmusTrade | Edit Offer';
  
  const navigate = useNavigate();
  const { contextUser, contextData } = useContext(UserContext);
  const { itemUid } = useParams();

  const categories = categoriesData.categories;
  const conditions = categoriesData.conditions;

  const [isOwner, setIsOwner] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [offerData, setOfferData] = useState(null);

  const [newName, setNewName] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [newCondition, setNewCondition] = useState('');
  const [newImage, setNewImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageURL, setImageURL] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isOfferDisabled, setIsOfferDisabled] = useState(false);

  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);
  const [isCategoryEmpty, setIsCategoryEmpty] = useState(false);
  const [isConditionEmpty, setIsConditionEmpty] = useState(false);
  const [isPriceEmpty, setIsPriceEmpty] = useState(false);
  const [isImageEmpty, setIsImageEmpty] = useState(false);

  useEffect(() => {
    const loadOfferData = async () => {
      try {
        const offerRef = doc(db, 'offers', itemUid);
        const offerSnapshot = await getDoc(offerRef);
        if (offerSnapshot.exists()) {
          if (contextUser && offerSnapshot.data().owner !== contextUser.uid) {
            setIsOwner(false);
            navigate('/');
          }

          const data = offerSnapshot.data();
          setOfferData(data);
          setNewName(data.name);
          setNewDescription(data.description);
          setNewCategory(data.category);
          setNewCondition(data.condition);
          setNewPrice(data.price);
          setImageURL(data.image);
          setIsOfferDisabled(data.disabled);


        } else {
          console.error('Offer not found.');
        }
      } catch (error) {
        console.error('Error loading offer data:', error);
      }
    };
    loadOfferData();
  }, [itemUid, contextUser]);

  const enableEditing = () => {
    setIsEditing(true);
  };

  const handleUpdateOffer = async () => {
    if (newName.trim() === '') {
      setIsNameEmpty(true);
      return;
    } else {
      setIsNameEmpty(false);
    }

    if (newDescription.trim() === '') {
      setIsDescriptionEmpty(true);
      return;
    } else {
      setIsDescriptionEmpty(false);
    }

    if (newCategory.trim() === '') {
      setIsCategoryEmpty(true);
      return;
    } else {
      setIsCategoryEmpty(false);
    }

    if (newCondition.trim() === '') {
      setIsConditionEmpty(true);
      return;
    } else {
      setIsConditionEmpty(false);
    }

    if (newPrice === '') {
      setIsPriceEmpty(true);
      return;
    } else {
      setIsPriceEmpty(false);
    }

    if (!imageURL) {
      setIsImageEmpty(true);
      return;
    } else {
      setIsImageEmpty(false);
    }
    try {
      const offerRef = doc(db, 'offers', itemUid);
      await updateDoc(offerRef, {
        name: newName,
        description: newDescription,
        category: newCategory,
        condition: newCondition,
        price: newPrice,
        image: imageURL,
        created: serverTimestamp(),
        disabled: isOfferDisabled,
      });

      setSuccessMessage('Offer updated successfully!');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating offer:', error);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setNewImage(e.target.files[0]);
    }
  };

  const handleImageUpload = async () => {
    try {
      if (newImage) {
        const uuid = v4();
        const storageRef = ref(storage, `images/${newImage.name}${uuid}`);
        const uploadTask = uploadBytesResumable(storageRef, newImage);

        setIsUploading(true);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
          },
          (error) => {
            console.error(error);
            setIsUploading(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setIsUploading(false);
              setProgress(0);
              setImageURL(downloadURL);
            });
          }
        );
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  if (!contextUser || !contextData) {
    return (
      <Container style={{ width: '100%', marginTop: '30px', fontSize: '18px' }} className="border p-4">
        <Placeholder animation="wave">
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
          <Placeholder xs={12} />
        </Placeholder>
      </Container>
    );
  }

  return (
    <Container fluid>
      <div className="d-flex flex-column align-items-center justify-content-start">
        <Form style={{ width: '100%', marginTop: '30px', fontSize: '18px' }} className="border p-4">
          <h2>Edit Your Offer</h2>
          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
              {successMessage}
            </Alert>
          )}

          <Form.Check
            disabled={!isEditing}
            type="switch"
            label="Deactivate Offer"
            id="disabled-custom-switch"
            checked={isOfferDisabled}
            onChange={(e) => setIsOfferDisabled(e.target.checked)}
          />

          <Form.Group className="mb-4" controlId="offer-name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value);
                setIsNameEmpty(false);
              }}
              style={{ fontSize: '16px' }}
              disabled={!isEditing}
              className={isNameEmpty ? 'is-invalid' : ''}
            />
            {isNameEmpty && <div className="invalid-feedback">Name cannot be empty</div>}
          </Form.Group>

          <Form.Group className="mb-4" controlId="offer-description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={newDescription}
              onChange={(e) => {
                setNewDescription(e.target.value);
                setIsDescriptionEmpty(false);
              }}
              style={{ fontSize: '16px' }}
              disabled={!isEditing}
              className={isDescriptionEmpty ? 'is-invalid' : ''}
            />
            {isDescriptionEmpty && <div className="invalid-feedback">Description cannot be empty</div>}
          </Form.Group>

          <Form.Group className="mb-4" controlid="offer-selectedCategory">
          <Form.Label>Category</Form.Label>
          <Form.Select 
            as="select" 
            value={newCategory} 
            onChange={(e) => {setNewCategory(e.target.value); setIsCategoryEmpty(false);}} 
            style={{ fontSize: '16px' }}
            disabled={!isEditing}
            className={isCategoryEmpty ? 'is-invalid' : ''}
          >
            <option value="" disabled>Select a category</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Form.Select>
          {isCategoryEmpty && <div className="invalid-feedback">Category cannot be empty</div>}
        </Form.Group>

        <Form.Group className="mb-4" controlid="offer-condition">
          <Form.Label>Condition</Form.Label>
          <Form.Select 
            as="select" 
            value={newCondition} 
            onChange={(e) => {setNewCondition(e.target.value); setIsConditionEmpty(false);}} 
            style={{ fontSize: '16px' }}
            disabled={!isEditing}
            className={isConditionEmpty ? 'is-invalid' : ''}
          >
            <option value="" disabled>Select a condition</option>
            {conditions.map((condition) => (
              <option key={condition} value={condition}>
                {condition}
              </option>
            ))}
          </Form.Select>
          {isConditionEmpty && <div className="invalid-feedback">Condition cannot be empty</div>}
        </Form.Group>


        <Form.Group controlId="offer-price" className="mb-4">
        <Form.Label>Price</Form.Label>
        <InputGroup>
          <Form.Control 
            type="number" 
            value={newPrice} 
            onChange={(e) => {setNewPrice(e.target.value); setIsPriceEmpty(false);}} 
            style={{ fontSize: '16px' }}
            disabled={!isEditing}
            className={isPriceEmpty || parseFloat(newPrice) < 0 ? 'is-invalid' : undefined} 
          />
          <InputGroup.Text>€</InputGroup.Text>
          {isPriceEmpty || newPrice < 0 ? <div className="invalid-feedback">Price cannot be empty or negative</div> : null}
        </InputGroup>
      </Form.Group>

          <Form.Group controlId="offer-image" className="mb-4">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleImageChange(e);
                setIsImageEmpty(false);
              }}
              disabled={!isEditing}
              className={isImageEmpty ? 'is-invalid' : undefined}
            />
            {isImageEmpty && <div className="invalid-feedback">Select an image</div>}
          </Form.Group>

          {newImage && (
            <>
            <div>
              <Button 
                onClick={handleImageUpload} 
                disabled={isUploading}
                style={{ marginBottom: '10px'}} 
                variant='secondary'
              >
                Upload Image
              </Button>
              {isUploading && <ProgressBar animated now={progress} />}
            </div>
            
            </>
          )}  

          <div>
            <Image
              src={imageURL}
              alt="Uploaded"
              fluid
              rounded
              style={{ border: 'solid black', maxWidth: '100%', maxHeight: '300px', margin: '10px 0' }}
            />
          </div>

          {!isEditing && (
              <Button variant="secondary" onClick={enableEditing} style={{ fontSize: '18px', marginRight: '10px' }}>
                Edit Offer
              </Button>
            )}
  
            
            {isEditing && (
                <>
                <Button 
                    variant='primary'
                    style={{ fontSize: '18px' }}
                    onClick={() => {
                        setNewName(offerData.name);
                        setNewDescription(offerData.description);
                        setNewCategory(offerData.category);
                        setNewCondition(offerData.condition);
                        setNewPrice(offerData.price);
                        setImageURL(offerData.image);
                        setIsEditing(false);
                    }}
                >
                    Discard Changes
                </Button>
                <Button variant="light" onClick={handleUpdateOffer} style={{ fontSize: '18px' }}>
                    Update Offer
                </Button>
                </>
            )}
        </Form>
      </div>
    </Container>
  );
};

export default CreateOfferForm;
