import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LowerNavbar from '../Components/LowerNavbar';

const PrivacyPage = () => {
  document.title = 'ErasmusTrade | Privacy';
  return (
    <>
    <LowerNavbar />
    <Container className="d-flex justify-content-center align-items-start mt-4" style={{ minHeight: '70vh' }}>
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center">
            <h2>Privacy</h2>
          </div>
          <div className="text-justify">
            <p>
              This Privacy Policy describes how ErasmusTrade ("we", "us", or "our") collects, uses, and shares personal information when you use our service (the "Service").
            </p>

            <h4>Information We Collect</h4>
            <ul>
              <li><strong>Personal Information:</strong> When you use our Service, we may collect certain personal information, such as your name, email address and phone number.</li>
              <li><strong>Usage Information:</strong> We may also collect information about how you use the Service, such as your IP address, browser type, pages visited, and other usage data.</li>
            </ul>

            <h4>How We Use Your Information</h4>
            <p>
              We may use the information we collect for various purposes, including to:
            </p>
            <ul>
              <li>Provide and maintain the Service</li>
              <li>Process transactions and fulfill orders</li>
              <li>Send you administrative communications</li>
              <li>Respond to your inquiries and customer service requests</li>
              <li>Personalize your experience and improve the Service</li>
              <li>Analyze usage trends and gather demographic information</li>
            </ul>

            <h4>Information Sharing</h4>
            <p>
              We may share your personal information with third parties in the following circumstances:
            </p>
            <ul>
              <li>With service providers who help us operate the Service</li>
              <li>In response to a legal request or obligation</li>
              <li>To protect the rights, property, or safety of our users or others</li>
            </ul>

            <h4>Data Security</h4>
            <p>
              We take reasonable measures to protect the security of your personal information and prevent unauthorized access or disclosure.
            </p>

            <h4>Your Choices</h4>
            <p>
              You may choose not to provide certain personal information, but this may limit your ability to use certain features of the Service.
            </p>

            <h4>Changes to this Privacy Policy</h4>
            <p>
              We may update this Privacy Policy from time to time, and any changes will be posted on this page.
            </p>

            <h4>Contact Us</h4>
            <p>
              If you have any questions about this Privacy Policy, please contact us using our <a href="/contact">contact form</a>.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default PrivacyPage;
