import React, { useState, useEffect } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { 
  auth
} from '../Config/firebase';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const PasswordReset = () => {
  document.title = 'ErasmusTrade | Reset Password';
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const resetPassword = async () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Reset email sent. Check your inbox.');
      setEmail('');
    } catch (error) {
      console.log('Password Reset error: ' + error);
    }
};

  useEffect(() => {
    document.title = 'Reset Password';
    return () => {
      document.title = 'ErasmusTrade';
    };
  }, []);

  return (
    <Container className="d-flex flex-column align-items-center justify-content-start" style={{ padding: '10px', minHeight: '80vh' }}>
      <Form style={{ width: '100%', maxWidth: '500px', fontSize: '16px' }} className="border p-3">
        <h2>Reset Password</h2>
        {successMessage && (
          <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
            {successMessage}
          </Alert>
        )}
        <Form.Group controlId='formBasicEmail' className="mb-4">
          <Form.Label>Enter your Email address</Form.Label>
          <Form.Control
            type='email'
            placeholder='name@example.com'
            className={`${isValidEmail ? '' : 'is-invalid'}`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsValidEmail(true); 
            }}
            style={{ fontSize: '16px' }}
          />
          {!isValidEmail && <Form.Text className='text-danger'>Invalid email address</Form.Text>}
        </Form.Group>
        <Button variant='primary' type='button' onClick={() => resetPassword()} style={{ fontSize: '18px' }}>
          Reset
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordReset;
