import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';

import { db } from '../Config/firebase';
import { addDoc, collection } from 'firebase/firestore';

import LowerNavbar from '../Components/LowerNavbar';

const ContactForm = () => {
  document.title = 'ErasmusTrade | Contact';
  const categories = ['General', 'Technical', 'Privacy', 'Business', 'Other'];
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isValidName, setIsValidName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidMessage, setIsValidMessage] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('General Inquiry');

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.name === '') {
      setIsValidName(false);
      return;
    }
    if (!validateEmail(formData.email)) {
      setIsValidEmail(false);
      return;
    }
    if (formData.message === '') {
      setIsValidMessage(false);
      return;
    }

    try {
      await addDoc(collection(db, 'contact'), formData);
      clearInput();
      setSuccessMessage('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const clearInput = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <>
      <LowerNavbar />
      <Container className="d-flex flex-column align-items-center justify-content-start" style={{ padding: '10px' }}>
        <Form onSubmit={handleSubmit} noValidate style={{ width: '100%', maxWidth: '500px', fontSize: '16px' }} className="border p-3">
          <h2>Contact</h2>

          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
              {successMessage}
            </Alert>
          )}

          <Form.Group controlId="formName" className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your Name"
              name="name"
              value={formData.name}
              onChange={(e) => {handleChange(e); setIsValidName(true);}}
              className={!isValidName ? 'is-invalid' : ''}
              autoComplete="name"
            />
            {!isValidName && <div className="invalid-feedback">Name cannot be empty</div>}
          </Form.Group>

          <Form.Group controlId="formEmail" className="mb-4">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Your Email"
            name="email"
            value={formData.email}
            onChange={(e) => {
                handleChange(e);
                setIsValidEmail(true);
              }}
            className={!isValidEmail ? 'is-invalid' : ''}
            autoComplete="email"
          />
          {!isValidEmail && <div className="invalid-feedback">Invalid email address</div>}
        </Form.Group>

        <Form.Group className="mb-4" controlId="formCategory">
          <Form.Label>Category</Form.Label>
          <Form.Select 
            value={selectedCategory} 
            onChange={(e) => {setSelectedCategory(e.target.value);}} 
            style={{ fontSize: '16px' }}
          >
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formMessage" className="mb-4">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Your Message"
            name="message"
            value={formData.message}
            onChange={(e) => {
              handleChange(e);
              setIsValidMessage(true);
            }}
            className={!isValidMessage ? 'is-invalid' : ''}
            autoComplete="off"  
          />
          {!isValidMessage && <div className="invalid-feedback">Message cannot be empty</div>}
        </Form.Group>


          <Button variant="primary" type="submit" style={{ fontSize: '16px', marginTop: '10px' }}>
            Submit
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default ContactForm;
