import React, { useEffect, useState, useContext } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {  db} from '../Config/firebase'; 
import OffersList from './OffersList'; 
import Container from 'react-bootstrap/Container';
import LowerNavbar from './LowerNavbar';
import OfferCard from './OfferCard';

import { UserContext } from '../Context/UserContext';

const MyOffers = () => {
    const { contextUser, contextData } = useContext(UserContext);
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        const fetchOffers = async () => {
          try {
            const offersCollection = collection(db, 'offers');

            const q = query(offersCollection, where('owner', '==', contextUser.uid));
            
            const offersSnapshot = await getDocs(q);
      
            const offersData = offersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setOffers(offersData);
          } catch (error) {
            console.error('Error fetching offers:', error.message);
          }
        };

        if (contextData) {
          fetchOffers();
        }
      }, [contextUser]);
      
      useEffect(() => {
        document.title = 'My Offers';
        return () => {
          document.title = 'ErasmusTrade';
        };
      }, []);

    if (!contextData) {
        return (
          <Container className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '70vh' }}>
            <h2>Loading...</h2>
          </Container>
        );
      }

    return (
        <>  
            <LowerNavbar />
            <Container className="d-flex flex-column align-items-center justify-content-start" style={{ minHeight: '70vh' }}>
            <h2>{offers.length !== 0 ? 'My Offers' : 'No Offers found'}</h2>
                {offers.map(offer => (
          <OfferCard key={offer.id} offer={offer} />
        ))}
            {offers.length === 0 && 
              <>
              <p>
                Create your first offer <a href="/new-offer">here</a>.
              </p>
              </>
            }
            </Container>
        </>
      );
};

export default MyOffers;