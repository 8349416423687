import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';

const BottomNavbar = () => {
  const navbarStyle = {
    borderTop: '1px solid #ddd', 
    marginTop: '20px', 
  };

  return (
    <Container>
      <Navbar variant="light" style={navbarStyle}>
        <Nav className="justify-content-center">
          <a className="nav-link" href="/about">
            About
          </a>
          <a className="nav-link" href="/contact">
            Contact
          </a>
          <a className="nav-link" href="/privacy">
              Privacy
          </a>
        </Nav>
      </Navbar>
    </Container>
  );
};

export default BottomNavbar;
