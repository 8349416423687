// to-do: replace last Link with a href


import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image'; 
import Container from 'react-bootstrap/Container';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../Config/firebase.js';
import { UserContext } from '../Context/UserContext.js';
import logo from '../Images/transfer.png';

const UpperNavbar = () => {
  const { contextUser, contextData } = useContext(UserContext);
  const [isSignedIn, setIsSignedIn] = useState(false);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Container>
      <Navbar bg="white" variant="light" style={{borderBottom: '1px solid #ddd'}}>
        <Navbar.Brand as={Link} to="/" className="home-link">
          <Image src={logo} alt="Logo" height="30" className="me-2" />
          ErasmusTrade Granada
        </Navbar.Brand>
        <Nav className="ms-auto">
          {!isSignedIn || !contextUser || !contextData ? (
            <Nav>
              <a href='/signup' className="nav-link me-2">Sign Up</a>
              <a href='/login' className="nav-link">Login</a>

            </Nav>
          ) : (
            <Nav>
              <a className="nav-link me-2" href="/account">Account</a>
              <a className="nav-link" href="/logout">Logout</a>
            </Nav>
          )}
        </Nav>
      </Navbar>
    </Container>
  );
  
};



export default UpperNavbar;
