import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Config/firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Placeholder from 'react-bootstrap/Placeholder';

import { UserContext } from '../Context/UserContext';

import { getDate } from './GetDate';

import config from '../Data/config';

const SearchItem = () => {
  const navigate = useNavigate();
  const { itemUid } = useParams();
  const [offerData, setOfferData] = useState(null);
  const [sellerData, setSellerData] = useState(null);
  const [copied, setCopied] = useState(false);
  const whatsAppShare = `https://api.whatsapp.com/send?text=Check%20out%20what%20I%20found%20on%20ErasmusTrade:%20https%3A%2F%2F${config.domain}/offer/${itemUid}`;
  const offer_url = `https://${config.domain}/offer/${itemUid}`;
  const [showModal, setShowModal] = useState(false);
  const [showContactOptions, setShowContactOptions] = useState(false);
  const { contextUser, contextData } = useContext(UserContext);
  const [allowContact, setAllowContact] = useState(false);
  const [whatsappContactLink, setWhatsAppContactLink] = useState('');
  const [isOwner, setIsOwner] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    if (contextUser && contextData) {
      setIsEmailVerified(contextUser.emailVerified);
      setAllowContact(true);
      if (offerData && offerData.owner === contextUser.uid) {
        setIsOwner(true);
      }
    } else {
      setAllowContact(false);
    }
    
  }, [contextUser, contextData, offerData]);

  const handleWhatsAppContact = () => {
    if (contextData && offerData && contextData.phone) {
      const whatsAppContactMessage = encodeURIComponent(`Hey, I saw your offer for *${offerData.name}* on ErasmusTrade and I'm interested in buying it!\n${offer_url}\n`);
      setWhatsAppContactLink(`https://wa.me/${sellerData.phone}?text=${whatsAppContactMessage}`);
      window.open(whatsappContactLink, '_blank');
    } else {
      alert('Please try a different contact method.');
    }
  };

  const handleEmailContact = () => {
    if (contextData && offerData && contextData.email) {
      const subject = encodeURIComponent(`[ErasmusTrade] Interested in purchasing ${offerData.name}`);
      const body = encodeURIComponent(`Hey ${sellerData.name}, 
  
  I saw your offer for "${offerData.name}" on ErasmusTrade and I'm interested in buying it! 
  
  Offer URL: ${offer_url}
  
  Looking forward to hearing from you.
  
  Best regards,
  ${contextData.name}`);
  
      const emailContactLink = `mailto:${sellerData.email}?subject=${subject}&body=${body}`;
      window.open(emailContactLink);
    } else {
      alert('Please try a different contact method.');
    }
  };
  

  const handleClickShare = () => {
    setShowModal(true);
  };

  const handleCloseShareModal = () => {
    setShowModal(false);
  };

  const handleCloseContact = () => {
    setShowContactOptions(false);
  };

  const handleClickContact = () => {
    if (!allowContact) {
      navigate('/login');
      return;
    }
    if (!isEmailVerified) {
      navigate('/verify-email');
      return;
    }
    setShowContactOptions(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(offer_url);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); 
  };

  const handleEditOffer = () => {
    navigate(`/offer/edit/${itemUid}`);
  };


  useEffect(() => {
    
    const getSellerInfo = async (offerData) => {
      try {
        const userRef = doc(db, 'users', offerData.owner);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists) {
          setSellerData(userDoc.data());
        } else {
          setSellerData(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    const fetchOfferData = async () => {
      try {
        const itemRef = doc(db, 'offers', itemUid);
        const itemDoc = await getDoc(itemRef);
  
        if (itemDoc.exists) {
          setOfferData(itemDoc.data());
          await getSellerInfo(itemDoc.data()); 
        } else {
          console.log('Item not found');
        }
      } catch (error) {
        console.error('Error fetching item data:', error);
      }
    };
  
    fetchOfferData();
  }, [itemUid]);
  

  useEffect(() => {
    document.title = offerData ? 'ErasmusTrade  | ' + offerData.name : 'ErasmusTrade';

    return () => {
      document.title = 'ErasmusTrade';
    };
  }, [offerData]);

  if (offerData && !isOwner && offerData.disabled) {
    return (
      <Container style={{ minHeight: '80vh' }}>
        <h2>This offer is no longer available.</h2>
        <p><a href='/'>Search</a> for new ones or <a href='/new-offer'>Create</a> your own.</p>
      </Container>
    );
  }

  return (
    <Container className="v mt-4">
      <Row>
        <Col md={8} className="mb-3 border p-3">
          {offerData ? (
            <>
              <h1 style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{offerData.name}</h1>
              <p style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Created: {getDate(offerData.created)}</p>
              {offerData.image && (
                <div className="mb-3">
                  <img src={offerData.image} alt="Offer" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                </div>
              )}
              <h3>Price</h3>
              <p>{offerData.price} €</p>
              <div className='d-flex flex-column' style={{ width: '100%', whiteSpace: 'normal' }}>
                <h3>Description</h3>
                <p style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
                  {offerData.description}
                </p>
              </div>
            </>
          ) : (
            <Placeholder as="div" animation="glow">
              <Placeholder xs={6} />
              <Placeholder xs={4} />
              <Placeholder xs={8} />
              <Placeholder xs={6} />
              <Placeholder xs={10} />
            </Placeholder>
          )}
        </Col>
        <Col md={4}>
          {offerData && sellerData ? (
              <>
                <div className="mb-2">
                  <Button onClick={handleClickContact} hidden={isOwner}>
                    Contact Seller
                  </Button>
                  <Button onClick={handleEditOffer} hidden={!isOwner}>
                    Edit Offer
                  </Button>
                </div>
                <div>
                  <Button onClick={handleClickShare} hidden={isOwner}>
                    Share
                  </Button>
                </div>
                <div className='owner-info mt-2' hidden={isOwner}>
                  By <span style={{ fontWeight: 'bold' }}>{sellerData.name}</span>
                  <br />
                  Active since {getDate(sellerData.created)}
                </div>
              </>
          ) : (
            <Placeholder as="div" animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={6} />
              <Placeholder xs={4} />
            </Placeholder>
          )}
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseShareModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <Button onClick={copyToClipboard}>
              {copied ? 'Copied!' : 'Copy Link'}
            </Button>
          </div>
          <div className="mb-2">
            <Button href={whatsAppShare} target="_blank" rel="noopener noreferrer">
              Share on WhatsApp
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showContactOptions} onHide={handleCloseContact} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Options</Modal.Title>
        </Modal.Header>
        {allowContact ? (
          offerData && sellerData ? (
            <Modal.Body>
              <div className="mb-2">
                <Button onClick={handleEmailContact}>
                  Email
                </Button>
              </div>
              <div className="mb-2">
                <Button onClick={handleWhatsAppContact}>
                  WhatsApp
                </Button>
              </div>
            </Modal.Body>
          ) : (
            <Modal.Body>
              <div className="mb-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
              </div>
              <div className="mb-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
              </div>
            </Modal.Body>
          )
        ) : (
          <Modal.Body>
            <div className="mb-2">
              <p><a href="/login">Login</a> or <a href="/signup">Sign Up</a> to contact the seller.</p>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </Container>
  );
}

export default SearchItem;
