import React from 'react';
import { auth } from '../Config/firebase.js'; 
import Container from 'react-bootstrap/Container';

const Logout = () => {
    try {
        auth.signOut();
    } 
    catch (error) {
        console.error(error);
    }

    return (
        <Container className="d-flex flex-column align-items-center justify-content-start" style={{ minHeight: '80vh' }}>
        <h2>Logged out successfully.</h2>
        </Container>
    );
};

export default Logout;