import { 
  auth
} from '../Config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';

const Login = () => {
  document.title = 'ErasmusTrade | Login';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');  

  const navigate = useNavigate();

  const signInWithEmail = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setEmailError("The email is not valid.");
        setIsValidEmail(false);
      }
      else if (error.code === "auth/wrong-password") {
        setPasswordError("The password is wrong.");
        setIsValidPassword(false);
      } else if (error.code === "auth/invalid-credential") {
        setErrorMessage("The credentials are not valid.");       
        clearPassword();
      } else {
        setErrorMessage('There was an error. Please try again later.');
        console.error('Error signing in:', error);
      }
    }
  };

  const clearPassword = () => {
    setPassword('');
  };

  const handleLogin = () => {
    if (!validateEmail(email)) {
      console.log(email + ' ' + validateEmail(email));
      setIsValidEmail(false);
      setEmailError('Your email is not valid.');
      return;
    }
    if (password.length < 6) {
      setPasswordError('Your password is at least 6 characters long.');
      setIsValidPassword(false);
      return;
    }
    signInWithEmail();
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return (
    <Container className="d-flex flex-column align-items-center justify-content-start" style={{ padding: '10px', minHeight: '70vh' }}>
      <Form style={{ width: '100%', maxWidth: '500px', fontSize: '16px' }} className="border p-3">
        <h2 className="mb-4">Login</h2>
        {errorMessage && (
          <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
            {errorMessage}
          </Alert>
        )}
        <Form.Group className="mb-4" controlId="login-email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsValidEmail(true);
            }}
            style={{ fontSize: '16px' }}
            className={isValidEmail ? '' : 'is-invalid'} 
          />
          {!isValidEmail && <div className='invalid-feedback'>{emailError}</div>}
        </Form.Group>
        <Form.Group className="mb-4" controlId="login-password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setIsValidPassword(true);
            }}
            style={{ fontSize: '16px' }}
            className={isValidPassword ? '' : 'is-invalid'} 
          />
          {!isValidPassword && <div className='invalid-feedback'>{passwordError}</div>}
        </Form.Group>
        <Button variant="primary" onClick={handleLogin} style={{ fontSize: '18px', marginBottom: '10px' }}>
          Login
        </Button>
        <Link to="/reset-password" className="btn btn-link" style={{ fontSize: '16px' }}>
          Forgot Password
        </Link>
      </Form>
    </Container>
  );
};

export default Login;
