import OffersList from '../Components/OffersList';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import filterData from '../Data/categories.json'; 

const Home = () => {
  document.title = 'ErasmusTrade';
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCondition, setSelectedCondition] = useState('');
  const [selectedSortBy, setSelectedSortBy] = useState('');

  const [searchCategory, setSearchCategory] = useState('');
  const [searchCondition, setSearchCondition] = useState('');
  const [sort, setSort] = useState(''); 

  const categories = filterData.categories;
  const conditions = filterData.conditions;
  const sortBys = filterData.sortBys;

  const handleSearch = () => {
    setSearchCategory(selectedCategory);
    setSearchCondition(selectedCondition);
    setSort(selectedSortBy);
  };

  return (
    <>
      <Container fluid>
        {/* Mobile Navbar */}
        <Navbar bg="light" variant="light" className='d-block d-sm-none'>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Form className="mr-auto">
              <Form.Select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">All Categories</option>
                {categories.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </Form.Select>

              <Form.Select
                value={selectedCondition}
                onChange={(e) => setSelectedCondition(e.target.value)}
                id="condition-form"
              >
                <option value="">All Conditions</option>
                {conditions.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </Form.Select>

              <Form.Select
                value={selectedSortBy}
                onChange={(e) => setSelectedSortBy(e.target.value)}
                id="sort-form"
              >
                <option value="">Sort By</option>
                {sortBys.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </Form.Select>
              <Button
                variant="outline-primary"
                className="search-button"
                onClick={() => handleSearch()}
                id="search-button"
              >
                Search
              </Button>
            </Form>

          </Navbar.Collapse>
        </Navbar>

{/* Non-Mobile Navbar */}
<Navbar bg="light" variant="light" className='d-none d-sm-block' expand="lg">
          <Container> {/* Ensuring the same container styling */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="justify-content-between">
              <Nav className="mr-auto">
              <Form.Select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                id="category-form"
              >
                <option value="">All Categories</option>
                {categories.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                value={selectedCondition}
                onChange={(e) => setSelectedCondition(e.target.value)}
                id="condition-form"
              >
                <option value="">All Conditions</option>
                {conditions.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </Form.Select>

              <Form.Select
                value={selectedSortBy}
                onChange={(e) => setSelectedSortBy(e.target.value)}
                id="sort-form"
              >
                <option value="">Sort By</option>
                {sortBys.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </Form.Select>
              <Button
                variant="outline-primary"
                className="search-button"
                onClick={() => handleSearch()}
                id="search-button"
              >
                Search
              </Button>
              </Nav>

              <Nav className="offer-buttons-nav">
                <Link className="nav-link" to="/new-offer">
                  New Offer
                </Link>
                <Link className="nav-link" to="/account/my-offers">
                  My Offers
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
      <Container className="d-flex flex-column align-items-center justify-content-start" style={{ minHeight: '70vh' }}>
        <OffersList
          key={`${searchCategory}${searchCondition}${sort}`}
          customKey={`${searchCategory}${searchCondition}${sort}`}
          category={searchCategory}
          condition={searchCondition}
          sort={sort}
          search={search}
        />
      </Container>
    </>
  );
};

export default Home;
