// App.js

import React from 'react';
import RouterComponent from './Components/RouterComponent';

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <div>
      <RouterComponent />
    </div>
  );
};

export default App;
