import React, { useState, useEffect } from 'react';
import { auth, db } from '../Config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore'; 

import PrivacyPage from '../Pages/Privacy';
import PrivateRoute from './PrivateRoute';
import Login from '../Pages/Login';
import SignUp from '../Pages/SignUp';
import CreateOfferForm from '../Components/CreateOfferForm';
import PasswordReset from '../Pages/PasswordReset';
import Logout from '../Components/Logout';
import Home from '../Pages/Home';
import SearchItem from '../Components/SearchItem';
import Account from '../Pages/Account';
import MyOffersPage from '../Pages/MyOffersPage';
import EmailVerificationCheck from '../Pages/EmailVerificationCheck';
import UpperNavbar from './UpperNavbar';
import About from '../Pages/About';
import BottomNavbar from './BottomNavbar';
import ContactForm from '../Pages/ContactForm';
import EditOffer from '../Components/EditOffer';
import ReversePrivateRoute from './ReversePrivateRoute';

import { UserContext } from '../Context/UserContext';

const RouterComponent = () => {
  const [currentUserId, setCurrentUserId] = useState(null);
  const [userForContext, setUserForContext] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserId(user.uid);
        setUserForContext(user);
      } else {
        setCurrentUserId(false);
        setUserForContext(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (currentUserId) {
          console.log('curr user id '+ currentUserId)
          console.log('Fetching user data IN CONTEXT')
          const userDoc = await getDoc(doc(db, 'users', currentUserId));
          const userDataRef = userDoc.data();
          setUserData(userDataRef);
          console.log('User data:', userDataRef);
        }
      } catch (error) {
        console.log('Error fetching user for Context: ' + error);
      }
    };

    fetchUserData();
  }, [currentUserId]);

  return (
    <UserContext.Provider value={{contextUser: userForContext, contextData: userData}}>
    <Router>
      <div>
        <UpperNavbar />
        <Routes>
            {/* General */}
            <Route path="/" element={<Home />} />

            {/* Authentication */}
            <Route path="/login" element={<ReversePrivateRoute user={currentUserId} />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="/reset-password" element={<ReversePrivateRoute user={currentUserId} />}>
              <Route path="/reset-password" element={<PasswordReset />} />
            </Route>
            <Route path="/signup" element={<SignUp />} />
            <Route path='/logout' element={<Logout />}/>
            <Route path="/verify-email" element={<EmailVerificationCheck />} />
            <Route path="/logout" element={<Logout />} />

            {/* Account & Offers */}
            <Route path="/account/my-offers" element={<PrivateRoute user={currentUserId} />}>
                <Route path="/account/my-offers" element={<MyOffersPage />} />
            </Route>
            <Route path="/account" element={<PrivateRoute user={currentUserId} />}>
                <Route path="/account" element={<Account user={currentUserId}/>} />
            </Route>
            <Route path="/offer/:itemUid" element={<SearchItem />} />
            <Route path="/offer/edit/:itemUid" element={<PrivateRoute user={currentUserId} />}>
                <Route path="/offer/edit/:itemUid" element={<EditOffer />} />
            </Route>
            <Route path="/new-offer" element={<PrivateRoute user={currentUserId} />}>
                <Route path="/new-offer" element={<CreateOfferForm />} />
            </Route>

            {/* Other */}
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/privacy" element={<PrivacyPage />} />

            {/* Wildcard */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <BottomNavbar />
      </div>
    </Router>
    </UserContext.Provider>
  );
};

export default RouterComponent;
