import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { getDate } from './GetDate';

const shortDescription = (text, maxLength) => {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const OfferCard = ({ offer }) => {
  
  const { 
    id, 
    name, 
    description, 
    category, 
    condition, 
    price, 
    image,
    created
  } = offer;
  const shortedDescription = shortDescription(description, 200);
  const navigate = useNavigate();

  return (
    <Card className="offer clickable" style={{ marginTop: '30px', fontSize: '18px', maxWidth: '800px' }}>
      <Row>
        <Col xs={12} md={4}>
          {image ? (
            <div style={{ width: '100%', overflow: 'hidden' }}>
              <Card.Img variant="top" src={image} alt={name} style={{ maxWidth: '100%' }} />
            </div>
          ) : (
            <Card.Img variant="top" src="placeholder.jpg" alt="No Image Available" style={{ margin: '10px', maxWidth: '100%' }} />
          )}
        </Col>
        <Col xs={12} md={8}>
          <Card.Body>
            <Card.Title style={{ fontSize: '24px', marginBottom: '10px' }}>{name}</Card.Title>
            <Card.Text style={{ fontSize: '16px', marginBottom: '10px' }}>{shortedDescription}</Card.Text>
            <Card.Text style={{ fontSize: '16px', marginBottom: '5px' }}>{category} / {condition}</Card.Text>
            <Card.Text style={{ fontSize: '16px', marginBottom: '5px' }}>Created: {getDate(created)}</Card.Text>
            <Card.Text style={{ fontSize: '16px', marginBottom: '5px' }}>Price: {price}€</Card.Text>
            <Button variant="primary" onClick={() => { navigate(`/offer/${id}`) }}>View Details</Button>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default OfferCard;
