import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyCaMVbdwHJDp2J8eEmNpzdrogBxJFRud38",
    authDomain: "erasmus-trade.firebaseapp.com",
    projectId: "erasmus-trade",
    storageBucket: "erasmus-trade.appspot.com",
    messagingSenderId: "939038881887",
    appId: "1:939038881887:web:0a3b783dfebe3f48af43ea",
    measurementId: "G-MKXEBRB65T"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
//const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);


export { 
    db, 
    storage,  
    auth, 
    googleProvider, 
    firebaseConfig 
};