import MyOffers from "../Components/MyOffers.js"
import React, { useEffect, useState } from 'react';
import { auth } from '../Config/firebase.js';
import { onAuthStateChanged } from "firebase/auth";
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyOffersPage = () => {
    document.title = 'ErasmusTrade | My Offers';

    const [currentUserId, setCurrentUserId] = useState(null);
    useEffect(() => {  
      const unsubscribe = onAuthStateChanged(auth, user => {
        if (user) {
          setCurrentUserId(user.uid);
        } else {
          setCurrentUserId(null);
        }
      });
  
      return () => unsubscribe(); // Cleanup the subscription when component unmounts
    }, [currentUserId]);
    

    return (
        <Container style={{ minHeight: '80vh' }}>
          <MyOffers currentUserId={currentUserId} />
        </Container>
    );
};

export default MyOffersPage;