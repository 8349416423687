import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

const LowerNavbar = () => {
  return (
    <Container>
      <Navbar bg="light" variant="light">
          <Nav className="ms-auto">
            <Link className="nav-link" to="/new-offer">
              New Offer
            </Link>
            <Link className="nav-link" to="/account/my-offers">
              My Offers
            </Link>
          </Nav>
      </Navbar>
    </Container>
  );
};

export default LowerNavbar;

